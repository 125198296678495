import React from 'react';
import { Button, Row } from 'react-bootstrap';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { CommunitySection } from '../../modules/common/components/community-section';

import './index.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { CategoryCard } from '../../modules/cs/main/components/category-card';
import { Link } from 'gatsby';

const NIKKEIndexPage: React.FC = () => {
  return (
    <DashboardLayout className={'home-page nikke '} game="nikke">
      <div className="dashboard-hero">
        <h1>Prydwen.gg - Goddess of Victory: NIKKE wiki and tier list</h1>
        <h2>
          Prydwen.gg is a wiki for Goddess of Victory: NIKKE. Check our guides,
          tier lists and reviews for characters available in the game.
        </h2>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <SectionHeader title="New content" />
      <div className="promoted-content">
        <div className="banner phantom">
          <h4>Phantom Review and Guide</h4>
          <Link to="/nikke/guides/guide-phantom">
            <Button variant="white">Read more</Button>
          </Link>
        </div>
      </div>
      <SectionHeader title="Shortcuts" />
      <h5>Newbie guides</h5>
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Introduction to the game"
          link="/nikke/guides/introduction-to-the-game"
          image={
            <StaticImage
              src="../../images/nikke/categories/category_guide.png"
              alt="Introduction to the game"
            />
          }
        />
        <CategoryCard
          title="Reroll guide"
          link="/nikke/guides/reroll"
          image={
            <StaticImage
              src="../../images/nikke/categories/category_reroll.png"
              alt="Reroll guide"
            />
          }
        />
        <CategoryCard
          title="Tier list"
          link="/nikke/tier-list"
          image={
            <StaticImage
              src="../../images/nikke/categories/category_tierlist.png"
              alt="Tier list"
            />
          }
        />
        <CategoryCard
          title="Meta teams (Story)"
          link="/nikke/guides/meta-teams"
          image={
            <StaticImage
              src="../../images/nikke/categories/category_beginner.png"
              alt="Early and late meta teams"
            />
          }
        />
        <CategoryCard
          title="Wishlist tips"
          link="/nikke/guides/wishlist"
          image={
            <StaticImage
              src="../../images/nikke/categories/category_shops.png"
              alt="Wishlist"
            />
          }
        />
        <CategoryCard
          title="Intro to PVP"
          link="/nikke/guides/pvp-intro"
          image={
            <StaticImage
              src="../../images/nikke/categories/category_pvp.jpg"
              alt="Intro to PVP"
            />
          }
        />
      </Row>
      <h5>Generic guides</h5>
      <Row
        xs={1}
        md={2}
        lg={2}
        xxl={3}
        className="g-3 category-cards-container"
      >
        <CategoryCard
          title="Harmony Cubes - suggestions"
          link="/nikke/guides/harmony-cubes-suggestions"
          image={
            <StaticImage
              src="../../images/nikke/categories/category_cubestips.png"
              alt="Harmony Cubes - suggestions"
            />
          }
        />
        <CategoryCard
          title="Skill investment Guide"
          link="/nikke/guides/skill-investment"
          image={
            <StaticImage
              src="../../images/nikke/categories/category_skills.jpg"
              alt="Skill investment Guide"
            />
          }
        />
        <CategoryCard
          title="Collection Items - Intro"
          link="/nikke/guides/collection-intro"
          image={
            <StaticImage
              src="../../images/nikke/categories/category_coll.jpg"
              alt="Collection Items - Intro"
            />
          }
        />
        <CategoryCard
          title="Collection Items - Upgrade Optimization"
          link="/nikke/guides/collection-dolls"
          image={
            <StaticImage
              src="../../images/nikke/categories/category_dolls.jpg"
              alt="Collection Items - Upgrade Optimization"
            />
          }
        />
        <CategoryCard
          title="Collection Items - Dispatch"
          link="/nikke/guides/collection-dispatch"
          image={
            <StaticImage
              src="../../images/nikke/categories/category_dispatch.jpg"
              alt="Collection Items - Dispatch"
            />
          }
        />
        <CategoryCard
          title="OL Gear - Intro"
          link="/nikke/guides/overload-gear-intro"
          image={
            <StaticImage
              src="../../images/nikke/categories/category_ol.webp"
              alt="Overload Gear - Intro "
            />
          }
        />
        <CategoryCard
          title="OL Gear - Recommendations"
          link="/nikke/guides/overload-gear-recommendations"
          image={
            <StaticImage
              src="../../images/nikke/categories/category_olrec.jpg"
              alt="OL Gear - Recommendations "
            />
          }
        />
        <CategoryCard
          title="OL Gear - Rerolling"
          link="/nikke/guides/overload-gear-reroll"
          image={
            <StaticImage
              src="../../images/nikke/categories/category_olreroll.jpg"
              alt="OL Gear - Rerolling "
            />
          }
        />
      </Row>
      <br />
      <div className="fuse-ad-placeholder" data-fuse="22844297256"></div>
      <SectionHeader title="Community" />
      <CommunitySection />
    </DashboardLayout>
  );
};

export default NIKKEIndexPage;

export const Head: React.FC = () => (
  <Seo
    title="NIKKE | Prydwen Institute"
    description="Prydwen.gg is a wiki for Goddess of Victory: NIKKE. Check our guides, tier lists and reviews for characters available in Global version of the game."
    game="nikke"
  />
);
